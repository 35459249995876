const routes = [
  {
    path: ['/', '/home'],
    exact: true,
    component: 'Home',
  },
  {
    path: ['/payment'],
    exact: true,
    component: 'Payment',
  },
  {
    path: ['/libraries'],
    exact: true,
    component: 'Copyright',
  },
  {
    path: ['/buy'],
    exact: true,
    component: 'Buy',
  },
  {
    path: ['/faq'],
    exact: true,
    component: 'FAQ',
  },
  {
    path: ['/roadmap'],
    exact: true,
    component: 'Roadmap',
  },
  {
    path: ['/license'],
    exact: true,
    component: 'AskForLicenseAccess',
  },
  {
    path: ['/license-manager'],
    exact: false,
    component: 'LicenseManager',
  },
  {
    path: ['/legal-advice'],
    exact: true,
    component: 'LegalAdvice',
  },
  {
    path: ['/privacy-policy'],
    exact: true,
    component: 'PrivacyPolicy',
  },
  {
    path: ['/cookie-policy'],
    exact: true,
    component: 'CookiePolicy',
  },
  {
    path: ['/user-agreement'],
    exact: true,
    component: 'UserAgreement',
  },
  {
    path: ['/refund-policy'],
    exact: true,
    component: 'RefundPolicy',
  },
];

export default routes;
